import React from "react";
import { Link } from "gatsby";

export default {
  title: "Terms and conditions for Capital",
  lastUpdated: "September 27, 2021",
  descMarkup: (
    <>
      This Agreement sets out the complete Terms and Conditions (the ‘Terms and
      Conditions’) which shall be applicable and legally binding on You
      (“Customer”, “Borrower”, “your”, “company”) and Brass Technologies
      Services (“Company”, “Brass”, “Us”, “We”, “Our”, “Lender”).
      <br /> <br />
      Please read this Agreement carefully before accepting to be bound by the
      Terms and Conditions
    </>
  ),
  sections: [
    {
      label: "1. Definitions and Interpretation",
      markup: (
        <>
          For the purposes of this Agreement, unless the context otherwise
          requires: <br /> <br /> <b>“Agreement”</b> means this agreement
          between the Customer and Brass which contains the Terms and
          Conditions; <br /> <br /> <b>“Account”</b> means your Brass account;
          <br /> <br /> <b>“Capital Limit”</b> means the maximum credit
          available to the Borrower by the Lender, following the creation of a
          Brass Account. Subject to our review, the Capital limit shall not
          exceed the sum of ₦10,000,000 (Ten Million Naira); <br /> <br />
          <b>“Disbursement Date”</b> means the date Brass disburses the Loan to
          the Customer’s Brass Bank Account which shall be within 2 days from
          the Customer’s receipt of the Loan approval; <br /> <br />
          <b>“Event of Default”</b> shall have the meaning ascribed to it in
          clause 13; <br /> <br /> <b>“Interest”</b> means an interest rate of
          1.08% per week on the Loan for the duration of the Loan Term or any
          other interest rate as may be communicated by Brass to the which
          duration shall not be less than 7 days; <br /> <br />
          <b>“Initial Loan Term”</b> means a period of 7 - 84 (Seven to
          eighty-four) days beginning from the Disbursement Date of the Loan.;
          <br /> <br /> <b>“Loan Term”</b> means collectively, an Initial Loan
          Term and the Additional Loan Term; <br /> <br /> <b>“Party”</b> means
          the Customer and Brass individually; <br /> <br /> <b>“Parties”</b>
          means the Customer and Brass collectively; <br /> <br />
          <b>“Personal Data”</b> means any information about the business and or
          company, and their stakeholders provided to us by you or in the use of
          our Site and services such as your name, email address, physical
          address, password, billing and payment information. This includes
          non-personal information like the domain name of your internet service
          provider, your geographical location, operating system and other
          relevant statistics; <br /> <br /> <b>“Privacy Policy” </b> means our
          privacy policy available on the Site, which sets out the manner in
          which personal data we collect from you, or you provide to us, will be
          used and processed by us; <br /> <br /> <b>“Repayment Date”</b> means
          such date (which maybe daily, weekly or every four weeks) as maybe
          agreed and selected by the Borrower in which the Loan and Interest
          shall become due and payable by the Borrower to Brass; <br /> <br />
          <b>“Site”</b> means Brass website and/or any other platform or media
          through which Brass offers its lending services; “Brass Bank Account”
          means a unique bank account with Wema Bank Plc or Sterling Bank Plc
          created for you and maintained by us and Banc Corp, upon setting up an
          Account on the Site;
        </>
      ),
    },
    {
      label: "2. Interpretations",
      markup: (
        <ol>
          <li>
            All headings in this Agreement are for convenience only and shall
            not be used in its interpretation. Unless this Agreement indicates a
            contrary intention.
          </li>
          <li> The singular shall include the plural and vice versa.</li>
          <li>
            A reference to any one gender shall be capable of being construed as
            a reference of any of the others.
          </li>
        </ol>
      ),
    },
    {
      label: "3.	Acceptance of Terms and Conditions",
      markup: (
        <ol>
          <li>
            By ticking the “I have read, understood and I agree to Brass’
            Privacy Policy, and Terms and conditions”, on the Site, which you
            hereby adopt as your electronic signature, you accept to be bound by
            the Terms and Conditions provided in this Agreement, and you agree
            that:
          </li>
          <li>
            You hereby expressly consent and authorise Brass and/or any of our
            duly authorised agents or partners, to debit your or Brass Bank
            Account for the purpose of repaying the Loan and accrued Interest as
            and when due;
          </li>
          <li>
            You consent to us transferring your data to third parties in foreign
            countries to process such data, in so far as such transfer is
            required for the performance of our obligations to you under this
            Agreement;
          </li>
          <li>
            We can provide materials and other information about your legal
            rights and duties to you electronically;
          </li>
          <li>
            We can send all important communications, billings statements,
            reminders and demand notices (collectively referred to as
            “Notifications”) to you electronically via our Site or to an email
            address you have provided us with;
          </li>
          <li>
            We are authorised to share, receive and use data/information
            collected from your transaction with other affiliated third-parties
            including but not limited to switching companies, credit bureaus and
            other financial institutions;
          </li>
          <li>
            We can alert you when Notifications are available, by sending you an
            electronic communication;
          </li>
          <li>
            By consenting, the electronic Notifications shall have the same
            meaning and effect as if we provided paper Notifications to you.
            When we send you an email alerting you that Notifications are
            available to you, such electronic Notifications shall have the same
            effect as paper Notifications whether or not you chose to view the
            Notifications, unless you had previously withdrawn your consent to
            receive electronic Notifications.
          </li>
          <li>
            Brass reserves the right, at its sole discretion, to modify or
            replace these Terms and Conditions from time to time. If you object
            to such changes, your sole recourse will be to cease using our
            services and the Site. Your continued use of our services and the
            Site following the changes to the Terms and Conditions will indicate
            your acknowledgement of such changes and agreement to be bound by
            the amended Terms and Conditions.
          </li>
        </ol>
      ),
    },
    {
      label: "4. Account, Opening Process",
      markup: (
        <ol>
          <li>
            In order to access our services, you must set up an Account on the
            Site wherein you shall provide us with all requested information
            about your registered company.
          </li>
          <li>
            You are required to provide us with basic information such as the
            registered name of your company, email address, phone number, Tax
            Identification Number (TIN), full names of the directors where
            applicable, names of shareholders and their percentage ownership,
            details of the founders (names, email addresses, phone numbers, and
            details of their referees), copies of the company’s incorporation
            documents, copies of government issued IDs of the shareholders of a
            company , and any other information needed by us to process your
            application.
          </li>
          <li>
            By providing us with information about your company, you represent
            and warrant that you have the authority to legally bind the entity
            and grant us all permissions and licenses to perform our obligations
            as provided in these Terms and Conditions.
          </li>
          <li>
            Our services and the Site, can only be utilised by persons over the
            age of 18. Brass the right to verify the authenticity of any Account
            created on the Site.
          </li>
        </ol>
      ),
    },
    {
      label: "5.	Requirements for Loan Request",
      markup: (
        <ol>
          <li>
            You must provide us with accurate, current and complete information
            at all times during the application process.
          </li>
          <li>
            Upon your request for a loan, Brass reserves the right to carry out
            an investigation to verify your status.
          </li>
          <li>
            Upon the approval of a Loan request, your Brass main Account will be
            credited with the requested loan amount.
          </li>
        </ol>
      ),
    },
    {
      label: "6. Brass Bank Account",
      markup: (
        <>
          To use the Brass Bank Account, you agree and acknowledge the
          following:
          <ol>
            <li>
              that you have the authorisation of the company to act on its
              behalf;
            </li>
            <li>
              that the company has a functional bank account with a financial
              institution domiciled in Nigeria;
            </li>
            <li>
              You shall provide us with all information as maybe requested by us
              for the creation of a Brass Bank Account for you. Such information
              shall include but shall not be limited to the company’s name,
              email address, phone number, and Bank Verification Number (BVN)
              (“Customer Information”);
            </li>
            <li>
              You represent and warrant that all Customer Information you
              provide to us is truthful, accurate, and complete;
            </li>
            <li>
              You agree to promptly notify us of changes to your Customer
              Information by updating your Account on the Site;
            </li>
            <li>
              For our compliance purposes and to provide the Brass Bank Account
              services to you, you hereby authorize us to, directly or through a
              third-party, obtain, verify, and record information and
              documentation that helps us verify your identity and the bank
              account information provided;
            </li>
            <li>
              and By using the Brass Account and providing Customer Information
              to us, you automatically authorize us to obtain, directly or
              indirectly and without any time limit or the requirement to pay
              any fees, information about you and your bank account from the
              financial institution holding your bank account and other
              databases as necessary to provide the Brass Account to you.
            </li>
          </ol>
        </>
      ),
    },
    {
      label: "7.	Loans and Loan Request Policy",
      markup: (
        <ol>
          <li>
            You hereby irrevocably authorise us to act on all requests for Loans
            received from you via the Site, and you shall be liable with respect
            thereof. Brass may however refuse to oblige any Loan requests at its
            sole and absolute discretion.
          </li>
          <li>
            Subject to our discretion, we reserve the right to reject any Loan
            request from you even if you have previously been issued a Loan by
            Brass.
          </li>
          <li>
            Following your Loan request, and subject to Brass’s discretion, your
            Brass Bank Account may be credited with the requested Loan. Where
            your Loan request is approved, you will immediately get a notice of
            approval via your Account and/or email confirming the amount to be
            disbursed into your Brass Bank Account, the Disbursement Date, the
            Interest on the Loan, and the Loan Term. Where your request is
            denied, a notice of denial will be communicated to you via your
            Account and/or email.
          </li>
          <li>
            You acknowledge that the Loan and accruable Interest shall be for
            the duration of the Loan Term, and you further agree that the Loan
            and the accrued Interest shall be repaid on the Repayment Date.
          </li>
          <li>You hereby agree and acknowledge that:</li>
          <li>
            Brass shall be deemed to have acted properly and to have fully
            performed all its obligations to you notwithstanding that your Loan
            request may have been initiated, or otherwise communicated in error
            or fraudulently, and you shall be bound by such request so far as
            Brass acted in good faith, believing that the Loan request was made
            by you.
          </li>
          <li>
            Brass may at its discretion, decline to act on or in accordance with
            the whole or any part of your Loan request pending further enquiry
            or further confirmation (whether written or otherwise) from you.
          </li>
          <li>
            You agree to indemnify Brass against all claims, losses, damages,
            costs and expenses howsoever arising in consequence of, or in any
            way related to Brass having acted in accordance with the whole or
            any part of any of your Loan request.
          </li>
          <li>
            To the full extent permitted by law, Brass shall not be liable for
            any unauthorised drawings, transfers, remittance, disclosures,
            activity or any incident on your account by the fact of the
            knowledge and/or use or manipulation of your Account password, ID or
            any means whether or not resulting from your negligence.
          </li>
          <li>
            Brass is authorised to act on such orders with respect to your
            Account and/or Brass Bank Account as may be required by any court
            order or competent authority or agency under the applicable law
          </li>
        </ol>
      ),
    },
    {
      label: "8.	The Borrower’s Obligations",
      markup: (
        <>
          The borrower agrees to:
          <ol>
            <li>
              repay the Loan, and the accrued Interest charged to its Account on
              or before the Repayment Date. Where the Borrower fails to repay
              Loan and Interest on or before the Repayment Date, Borrower’s
              agrees that the Borrower’s Brass account shall be disabled from
              sending payments two (2) days after the default of the repayment
              obligation.
            </li>
            <li>
              be held responsible for any unauthorized Loan request using its
              Account unless the Borrower notifies the Lender in writing, of
              imminent fraud by an unauthorised user on the Borrower’s account
              within 24 hours of such fraud;
            </li>
            <li>
              repay the Loan and accrued Interest via direct transfer to Brass
              via its Brass Account, or an electronic debit from its Brass Bank
              Account. The Borrower shall have the option to repay all
              outstanding sums at any point prior to the Repayment Date,
              provided that the Loan and Interest amount is repaid by the end of
              the Loan Term;
            </li>
            <li>
              and not use the Site and Brass’s services for any act of
              illegality or criminality and that Brass shall not be legally or
              criminally liable for any illegality committed by it.
            </li>
          </ol>
        </>
      ),
    },
    {
      label: "9. The Lender’s Obligations",
      markup: (
        <>
          The lender agrees to:
          <ol>
            <li>
              make the Loan available to the Borrower on the Disbursement Date
              (which shall in no case exceed the Credit Limit) subject to the
              Lender verifying and approving the Borrower’s Loan request;
            </li>
            <li>
              conduct adequate checks on the Borrower prior to approving a Loan
              request;
            </li>
            <li>
              demand repayment of Loans and Interest from the borrower as and
              when due;
            </li>
            <li>
              and To use all reasonable and legitimate means to recover unpaid
              Loans and Interest. Such means of recovery shall include engaging
              collection agencies, and initiating court processes for the
              recovery of debts.
            </li>
          </ol>
        </>
      ),
    },
    {
      label: "10.	Personal Information",
      markup: (
        <ol>
          <li>
            You agree to grant Brass an irrevocable right to collect and use any
            information you provide us or we collect from you, for the specific
            purpose of providing you with our services.
          </li>
          <li>
            You hereby agree and authorise us to verify information provided by
            you to us against your information held by local banks including but
            not limited to your phone number, date of birth, National
            Identification Number (I.D), or Passport Number, Bank Verification
            Number (BVN) and any other information that will enable us identify
            you and comply with the regulatory “Know Your Customer” (KYC)
            requirements in your country.
          </li>
          <li>
            Brass reserves the right to request for further information from you
            pertaining to your Loan request or the creation of your Account, and
            Brass Bank Account at any time. Failure to provide such information
            within the time required by Brass may result in Brass declining to
            accept your Loan request or a delay the creation of your Account, or
            Brass Bank Account.
          </li>
          <li>
            You consent that we may disclose and or transfer your information to
            third parties or any other entity we deem necessary to perform our
            obligations to you under this Agreement.
          </li>
          <li>
            All other provisions regarding the use of your personal information
            are contained in our Privacy Policy.
          </li>
        </ol>
      ),
    },
    {
      label: "11.	Intellectual Property",
      markup: (
        <ol>
          <li>
            a) The Site and our services contained therein, original content,
            features and functionality are and shall remain the exclusive
            property of Brass. Our trademarks and trade name may not be used in
            connection with any product or service without our prior written
            consent.
          </li>
          <li>
            b) You shall not use, adapt, modify, prepare derivative works of,
            distribute, license, sell, transfer, publicly display, publicly
            perform, transmit, broadcast or otherwise exploit the Site or
            collective content except as expressly permitted by Brass.
          </li>
          <li>
            c) Subject to your compliance with these Terms and Conditions, Brass
            grants you a limited, non-exclusive, revocable, non-transferable
            licence to access and view any collective content made available on
            or through the Site and accessible to you.
          </li>
        </ol>
      ),
    },
    {
      label: "12.	Representations and Warranties",
      markup: (
        <>
          You make the following representations and warranties to us at the
          time of agreeing to these Terms and Conditions:
          <ol>
            <li>You are over the age of 18 years;</li>
            <li>
              You are of sound mind and have the capacity to use our Site and
              services;
            </li>
            <li>
              All information that you provide to us is true and accurate to the
              best of your knowledge;
            </li>
            <li>
              That your company has been duly registered as a legal entity at
              the office of the appropriate companies’ registration agency;
            </li>
            <li>
              You have carefully read and understood these Terms and Conditions;
            </li>
            <li>
              That neither the company nor the stakeholders are subject to any
              bankruptcy claim, and/or pending or threatened criminal
              proceedings, that may in any way have adverse effect on the
              financial assets of the company or stakeholders in the opinion of
              Brass;
            </li>
            <li>
              If you are accepting these Terms and Conditions on behalf of a
              legal entity, you are legally authorised to do so and we may
              request evidence of such legal entitlement; and
            </li>
            <li>
              That by accepting these Terms and Conditions, neither the company
              nor the stakeholders are not breaching any laws, regulations or
              agreements that are applicable to them
            </li>
            <li></li>
          </ol>
        </>
      ),
    },
    {
      label: "13. Event of default",
      markup: (
        <>
          An Event of Default refers to the following instances:
          <ol>
            <li>
              you fail to pay the Loan and accrued Interest two days after the
              agreed Repayment Date;
            </li>
            <li>
              any representation or information made or given by you in
              connection with your Account and Loan request is incorrect,
              inaccurate or misleading;
            </li>
            <li>
              you carry out or attempt to carry out any act which may prejudice
              Brass’s rights under this agreement; ing any accrued Interest or
              fees payable on the Loan).
            </li>
            <li>
              you are declared bankrupt, insolvent or unable to pay your debt
              according to the appropriate laws in your country during the Loan
              Term:
              <ul
                style={{
                  listStyleType: "disc",
                  margin: "1.5rem 0 1.5rem 1.5rem",
                }}
              >
                <li>
                  Upon the occurrence of an Event of default as mentioned above,
                  we may without prejudice to any other right or remedy granted
                  to us under any law
                </li>
                <li>terminate this agreement in accordance with clause 14; </li>
                <li>
                  declare that the Loan and all accrued Interest under this
                  Agreement becomes due and payable and you shall immediately
                  make all necessary payments; and
                </li>
                <li>
                  deduct funds from your Brass Bank Account or any other bank
                  account as permitted by law upon compliance with the relevant
                  legal requirements, as payment for your outstanding repayment
                  obligations.
                </li>
              </ul>
            </li>
            <li>
              Brass reserves the right to institute legal proceedings against
              you to recover the Loan, and accrued Interest, with or without
              prior notice.
            </li>
            <li>
              You shall be liable for all legal costs and expenses incurred by
              Brass for any action taken against you by Brass for the recovery
              and repayment of the Loan (including any accrued Interest or fees
              payable on the loan).
            </li>
            <li>
              You agree that upon the occurrence of an Event of default, Brass
              or its authorised representatives or collections agent may notify
              you and any other person who in Brass’s opinion, may assist in the
              recovery and repayment of the outstanding Loan (including any
              accrued Interest or fees payable on the Loan). <br />
              <br /> Upon the occurrence of an Event of default, Brass reserves
              the right to hold and utilise the personal information (including
              but not limited to their Identification Number (I.D), phone number
              and email address) of your directors, shareholders or any other
              authorized personnel as the case may be, as part of the measures
              to guarantee recovery and repayment of the Loan (including any
              accrued Interest or fees payable on the Loan).
            </li>
          </ol>
        </>
      ),
    },
    {
      label: "14. Termination",
      markup: (
        <ol>
          <li>
            We may terminate or suspend your Account immediately with or without
            prior notice or liability, for any reason whatsoever, including
            without limitation to if you breach these Terms and Conditions; or
          </li>
          <li>
            Upon the occurrence of an Event of Default as stated in clause 13
            above.
          </li>
          <li>
            Upon termination, your right to use our Site and services will
            immediately cease, and all outstanding Loans (including any accrued
            Interest or fees payable on the Loan) shall become payable. If you
            wish to terminate your Account, you may simply discontinue using the
            Site and our services.
          </li>
        </ol>
      ),
    },
    {
      label: "15. Idemnity",
      markup: (
        <>
          You agree to indemnify and hold harmless Brass, its affiliates and
          subsidiaries, its officers, directors, and employees, against all
          liabilities, costs, expenses, damages and losses (including any
          direct, indirect or consequential losses, loss of profits, loss of
          reputation and all interest penalties and legal and other reasonable
          professional costs and expenses) suffered or incurred by it, its
          affiliates and subsidiaries, its officers, directors, and employees,
          as a result of:
          <ol>
            <li>your fraudulent or illegal use of our Site or services;</li>
            <li>
              any act of negligence or willful conduct by the stakeholders of
              the company with respect to fulfilling your obligations in
              connection with this Agreement;
            </li>
            <li>
              any inaccurate, incomplete or misleading information that you have
              provided to us;
            </li>
            <li>
              any unauthorised access to your Account as a result of your
              failure to keep your username and password confidential;
            </li>
            <li>
              any service that you have offered, whether with or without our
              permission to a third party using the Company’s services or Site;
            </li>
            <li>
              and any claim made against the company and/or its stakeholders for
              actual or alleged infringement of a third party’s intellectual
              property rights arising out of or in connection with the use of
              our services or use of the Site.
            </li>
          </ol>
        </>
      ),
    },
    {
      label: "16.	Force Majeure",
      markup: (
        <>
          Neither Party shall be liable for failure to perform, or for delay in
          performing its obligations hereunder if such failure or delay shall be
          due to Acts of God, war, riot, civil commotion, weather, labour
          disputes, failure of sub-contractors or any other cause beyond its
          reasonable control.
        </>
      ),
    },
    {
      label: "17.	Limitation of Liability",
      markup: (
        <>
          In no event shall Brass, its directors, employees, partners or
          affiliates, be liable for any indirect, incidental, special,
          consequential or punitive damages, including without limitation, loss
          of profits, data, use, goodwill, or other intangible losses, resulting
          from your use of the Site or our services or your inability to use the
          Site or our services.
        </>
      ),
    },
    {
      label: "18.	Severability",
      markup: (
        <p>
          If any part of this Agreement is held to be invalid or unenforceable
          in any jurisdiction, the invalidity and unenforceability of all
          remaining provisions contained in this Agreement shall not in any way
          be affected or impaired.
        </p>
      ),
    },
    {
      label: "19.	Entire Agreement",
      markup: (
        <p>
          This Agreement contains the entire agreement between the Parties with
          respect to the subject matter and supersedes all prior arrangements
          and understandings (whether oral or written).
        </p>
      ),
    },
    {
      label: "20.	Waiver",
      markup: (
        <>
          In the event Brass does not strictly enforce its rights under this
          Agreement (including its right to insist on the repayment of all sums
          due on the Repayment Date), Brass shall not be deemed to have waived
          or lost those rights and will not be prevented from enforcing such
          rights at a later date.
        </>
      ),
    },
    {
      label: "21.	Assignment",
      markup: (
        <>
          Brass reserves the right to transfer or assign its rights and
          obligations under this Terms and Conditions. Brass will only notify
          the you if such an assignment will change the arrangements for the
          administration of the Loan.
        </>
      ),
    },
    {
      label: "22.	Amendment",
      markup: (
        <>
          Brass may at any time vary revise these Terms and Conditions by
          updating this document. You agree to be bound by subsequent revisions
          and agree to review these Terms and Conditions periodically for
          changes. The most updated version of this document will be available
          for your review under the “Terms and Conditions” that appears on the
          Site.
        </>
      ),
    },
    {
      label: "23.	Governing Law and Dispute Resolution",
      markup: (
        <ol>
          <li>
            These Terms and Conditions shall be governed by and construed in
            accordance with the laws of the Federal Republic of Nigeria.
            <br />
            <br />
          </li>
          <li>
            In the event of a dispute, we both undertake that we shall make an
            effort to achieve an amicable settlement. The discussions will be
            handled by our Authorised Representatives and you or your authorised
            representatives. Any dispute arising out of this Agreement which
            cannot be settled, by mutual agreement/negotiation within 1 (one)
            month from the commencement of the dispute shall be, shall be
            subject to the jurisdiction of the courts of the Federal Republic of
            Nigeria.
            <br />
            <br />
          </li>
          <li>
            Provided that if the value of the dispute is above N10,000,000 (ten
            million naira), the parties shall refer the matter to arbitration
            under the provisions of the Arbitration and Conciliation Act 2004.
            The arbitration shall be presided over by a sole arbitrator
            appointed by the agreement of the parties. Where the parties are
            unable to agree on the choice of the sole arbitrator within ten (10)
            days of the declaration of the dispute, the appointment shall be
            referred to the Chairman of the Chartered Institute of Arbitration
            UK (Nigerian Branch). The seat of the arbitration shall be Lagos
            Nigeria.
          </li>
        </ol>
      ),
    },
    {
      label: "24.	Class Action Waiver",
      markup: (
        <>
          You agree that any claims will be adjudicated on an individual basis,
          and you waive any rights that you may have to participate in a class,
          collective, or other joint action with respect to any claims that you
          may have against us.
        </>
      ),
    },
    {
      label: "25. Contact us",
      markup: (
        <>
          If you have any questions about this Terms and Conditions, please
          contact us at <a href="mailto:help@trybrass.com">help@trybrass.com</a>
        </>
      ),
    },
  ],
};
