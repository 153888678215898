import React from "react";
import { Link } from "gatsby";

export default {
  title: "Privacy policy",
  lastUpdated: "October 10, 2023",
  sections: [
    {
      label: "Introduction",
      markup: (
        <>
          This Privacy Policy (the <b>“Policy”</b> ) applies to Brass
          Technologies Limited’s online interface (i.e., website or mobile
          application) and any Brass Invoicing Limited’s affiliate, subsidiary
          or online interface that is linked to this Policy, (individually
          referred to as a “site”, and, collectively, <b>“sites”</b> ). The term{" "}
          <b>“the company”</b> or <b>"Brass"</b> or <b>"we"</b> or <b>"us"</b>{" "}
          or <b>"our"</b> in this Policy shall refer to Brass Invoicing Limited,
          and any banking and non-banking affiliates or subsidiaries of Brass
          Invoicing Limited that is linked to this Policy. This Policy describes
          how the Sites may collect, use, and share information from or about
          you, and explains how information may be collected and used for
          advertising purposes.
          <br />
          <br />
          Brass takes the privacy of your information very seriously. This
          Policy explains how and for what purposes we may use the information
          collected from you via the Site.
          <br />
          <br />
          Additional information on our Privacy Policy may be found on our Sites
          and within Frequently Asked Questions (FAQs) as set out on the Sites.
          <br />
          <br />
          Please read this Policy carefully. By using the Site and any services
          we offer via the Site, you are agreeing to be bound by this Policy
          with respect to the information collected about you via this Site.
          <br />
          <br />
          You may use the Site only for lawful purposes. You may not use the
          Site:
          <ul>
            <li>
              In any way that breaches any applicable local, national or
              international law or regulation.
            </li>
            <li>
              In any way that is unlawful or fraudulent or has any unlawful or
              fraudulent purpose or effect.
            </li>
            <li>
              For the purpose of harming or attempting to harm any person and in
              any way.
            </li>
            <li>To bully, insult, intimidate or humiliate any person.</li>
            <li>
              To send, knowingly receive, upload, download, use or re-use any
              material which does not comply with our content standards.
            </li>
            <li>
              To transmit, or procure the sending of, any unsolicited or
              unauthorised advertising or promotional material or any other form
              of similar solicitation (spam).
            </li>
            <li>
              To knowingly transmit any data, send or upload any material that
              contains viruses, trojan horses, worms, time-bombs, keystroke
              loggers, spyware, adware or any other programs or similar computer
              code designed to adversely affect the operation of any computer
              software or hardware.
            </li>
          </ul>
          You also agree:
          <ul>
            <li>
              Not to reproduce, duplicate, copy or re-sell any part of our Site
              as this is in contravention of the provisions of our terms of use.
            </li>
            <li>
              Not to access without authority, interfere with, damage or
              disrupt:
              <ol style={{ listStyleType: "lower-alpha" }}>
                <li>any part of our Site;</li>
                <li>
                  any equipment or network on which our Site is stored; or
                </li>
                <li>any software used in the provision of our Site.</li>
              </ol>
            </li>
          </ul>
        </>
      ),
    },
    {
      label: "Personal information collected",
      markup: (
        <>
          We may collect the following information from you:
          <ul>
            <li>name, username, gender, date of birth;</li>
            <li>email address, postal address, telephone number;</li>
            <li>your interests;</li>
            <li>account number, card number and expiry date</li>
            <li>or any other information that may be required</li>
          </ul>
          Although it is not compulsory to give us this information if you do
          not, we may not be able to provide you with the full range of services
          that the Site has to offer.
        </>
      ),
    },
    {
      label: "Use of collected information",
      markup: (
        <>
          The information we collect is to provide users with a secure, smooth,
          efficient, and customised experience. The information we collect may
          also be used to: provide our additional Services which you may
          subscribe to and customer support; process transactions and send
          notices about your transactions; verify your identity, including
          during account creation and password reset processes; resolve
          disputes, collect fees, and troubleshoot problems; manage risk, or to
          detect, prevent, and/or remediate fraud or other potentially
          prohibited or illegal activities; detect, prevent or remediate
          violations of policies or applicable user agreements; improve the
          offering of our Services to you by customising your user experience;
          measure the performance of our Services and improve their content and
          layout; manage and protect our information technology infrastructure;
          provide service update notices, and deliver promotional offers based
          on your communication preferences; contact you at any telephone
          number, by placing a voice call or through text (SMS) or email
          messaging; perform creditworthiness and solvency investigation, and
          compare information for accuracy and verify it with third parties.
          <br />
          <br />
          We may also contact you via electronic means to notify you regarding
          your account, to troubleshoot problems with your account, to resolve a
          dispute, to collect fees or monies owed, to poll your opinions through
          surveys or questionnaires, or as otherwise necessary to service your
          account.
          <br />
          <br />
          Conclusively, we may contact you with the information provided as
          necessary to enforce our policies, applicable law, or any agreement we
          may have with you. When contacting you via phone, to reach you as
          efficiently as possible we may use, and you consent to receive,
          autodialled or pre-recorded calls and text messages. Where applicable
          and permitted by law, you may decline to receive certain
          communications.
        </>
      ),
    },
    {
      label: "Record Retention",
      markup: (
        <>
          Brass shall maintain all necessary records on transactions, both
          domestic and international; all records obtained through CDD measures,
          account files and business correspondences and results of any analysis
          undertaken for at least five (5 ) years following completion of the
          transaction. This will enable Brass to comply swiftly with information
          requests from Competent authorities in tandem with FATF
          Recommendation. To accomplish this, document retention policies has
          been set and procedures would be established for maintaining AML/CFT
          records. In establishing document retention policy, Brass would be
          guided by both statutory requirements and the needs of the
          investigating authorities on the one hand and commercial
          considerations, on the other. .
          <br />
          <br />
          The broad categories of AML/CFT-related records are:
          <ul>
            <li>Customer identification and verification documents.</li>
            <li>
              Transaction records, including currency transaction reports.
            </li>
            <li>
              Suspicious transaction reports, together with supporting
              documentation.
            </li>
            <li>
              AML/CFT-related records may be maintained by way of original
              documents, stored in microfiche, and in computerized or electronic
              form, subject to the provisions of the law on what is acceptable
              as evidence.
            </li>
          </ul>
          The Compliance Officer or other designated officer shall be
          responsible for ensuring that all AML/CFT records are maintained
          properly and kept for not less than five (5 ) years before they are
          sent to the archives.
        </>
      ),
    },
    {
      label: "Sharing collected information",
      markup: (
        <>
          Services available on this Site are offered in conjunction with some
          of our business partners, including Bancorp Bank Limited (the
          "business partners").
          <br />
          <br />
          In order for services to be provided, we may share some necessary
          details about you with the Business Partners, and according to our
          business dynamics and the continued provision of efficient services to
          you, we may, from time to time, transfer such details to other
          Business Partners as we deem fit. However, under any circumstance, we
          will take steps to ensure that your privacy rights continue to be
          protected.
          <br />
          <br />
          We may transfer your personal information to any other third party as
          part of a sale of some or all of our business and assets to any third
          party or as part of any business restructuring or reorganisation, but
          we will take steps with the aim of ensuring that your privacy rights
          continue to be protected.
          <br />
          <br />
          In addition, we may pass your information onto one of our carefully
          selected business partners or to other carefully selected third
          parties to enable them to send you information which may be of
          interest to you but only if you have given us permission to do so. You
          can stop receiving such mail whenever you choose by unsubscribing via
          the link provided.
          <br />
          <br />
          Other than as set out above, we will not disclose any of your personal
          information without your permission unless we are required by law to
          do so (for example, if required to do so by a court order or for the
          purposes of prevention of fraud or other crime).
        </>
      ),
    },
    {
      label: "Information automatically collected from your computer",
      markup: (
        <>
          We and our third-party service providers may collect and use other
          information in a variety of ways, including:
          <ul>
            <li>
              <b>Log files/IP addresses:</b> When you visit the Site, our web
              server automatically records your IP address. This IP address is
              not linked to any of your personal information. We use IP
              addresses to help us administer the Site and to collect
              demographic information for aggregation purposes.
              <br />
              <br />
            </li>
            <li>
              <b>
                Other technologies including pixel tags, web beacons, and clear
                gifs:
              </b>{" "}
              These may be used in connection with some Site pages, downloadable
              mobile applications and HTML-formatted email messages to measure
              the effectiveness of our communications, the success of our
              marketing campaigns, to compile statistics about usage and
              response rates, to personalize/tailor your experience while
              engaging with us online and offline, for fraud detection and
              prevention, for security purposes, for advertising, and to assist
              us in resolving account holders’ questions regarding the use of
              our Site.
              <br />
              <br />
            </li>
            <li>
              <b> Aggregated and de-identified data:</b> Aggregated and
              De-identified Data is data that we may create or compile from
              various sources, including but not limited to accounts and
              transactions. This information, which does not identify individual
              account holders, may be used for our business purposes, which may
              include offering products or services, research, marketing or
              analysing market trends, and other purposes consistent with
              applicable laws.
              <br />
              <br />
            </li>
            <li>
              <b>Through your browser or device:</b> Certain information is
              collected by most browsers and/or through your devices, such as
              your Media Access Control (MAC) address, device type, screen
              resolution, operating system version and internet browser type and
              version. We use this information to ensure Sites function
              properly, for fraud detection and prevention, and security
              purposes.
            </li>
          </ul>
        </>
      ),
    },
    {
      label: "Cookies",
      markup: (
        <>
          When you visit the Site we may store some information (commonly known
          as a ) on your computer. Cookies are small files of information which
          use a unique identification tag and are stored on your device as a
          result of using the Site or other services we provide to you. A number
          of cookies we use last only for the duration of your session and
          expire when you close your browser. Other cookies are used to remember
          you when you return to the site and will last for longer. A cookie
          helps you get the best out of the Site and helps us to provide you
          with a more customised service. We use cookies:
          <ul>
            <li>
              so that you will not have to re-enter your details each time you
              visit the Site to track how our Site is used and to improve and
              update our content
            </li>
            <li>store your preferences</li>
            <li>
              customise elements of the layout and/or content of the site for
              you
            </li>
            <li>
              collect statistical information about how you use the site so that
              we can improve the site
            </li>
          </ul>
          You can refuse to accept these cookies and most devices and browsers
          offer their own privacy settings for cookies. You will need to manage
          your cookie settings for each device and browser you use. However, if
          you do not accept these cookies, you may experience some inconvenience
          in your use of the Site and some online products and services. For
          example, we will not be able to recognize your device and you will
          need to answer a challenge question each time you log on. You also may
          not receive tailored advertising or other offers from us that may be
          relevant to your interests and needs.
        </>
      ),
    },
    {
      label: "Advertisement and information about other products and services",
      markup: (
        <>
          From time to time we may send you information about other financial
          products and services offered by the Company that we think may be of
          interest to you.
          <br />
          <br />
          Brass advertises online (e.g., pages within our Sites and mobile apps,
          through the Company’s managed social media presence, and on other
          sites and mobile apps not affiliated with Brass) and offline (e.g. in
          banking centres, through call centres, and direct marketing). In order
          to understand how our advertising performs, we may collect certain
          information on our Sites and other sites and mobile apps through our
          advertising service providers using cookies, IP addresses, and other
          technologies. The collected information may include the number of page
          visits, pages viewed on our Sites, search engine referrals, browsing
          activities over time and across other sites following your visit to
          one of our Sites or Apps, and responses to advertisements and
          promotions on the Sites and on sites and apps where we advertise.
          <br />
          <br />
          Brass uses the information described in this Policy to help advertise
          our products and services. We use such information to:
          <ul>
            <li>
              Present tailored ads to you, including;
              <li>
                Banner ads and splash ads that appear as you sign on or off of
                your online accounts on our Sites, within mobile banking and
                other mobility applications;
              </li>
              <li>E-mail, postal mail, and telemarketing;</li>
              <li>On other sites and mobile apps not affiliated with Brass;</li>
              <li>Analyse the effectiveness of our ads; and</li>
              <li>
                Determine whether you might be interested in new products or
                services
              </li>
            </li>
          </ul>
          You can tell us to stop this at any time by sending an email to{" "}
          <a href="mailto:help@trybrass.com">help@trybrass.com</a>
          <br />
          <br />
          Advertising on third party sites and mobile apps: Brass contracts with
          advertising companies to advertise our products and services on sites
          and mobile apps not affiliated with us. We may use Aggregated and
          De-identified Data and information provided by you to these
          third-party sites and mobile apps to select which of our
          advertisements or offers may appeal to you, display them to you and
          monitor your responses. Third-Party Sites and mobile apps are not
          subject to Brass Privacy Policy. Please visit the individual sites and
          mobile apps for additional information on their data and privacy
          practices and opt-out policies.
        </>
      ),
    },
    {
      label: "Changes to your details",
      markup: (
        <>
          Keeping your account information accurate and up to date is very
          important. If your account information is incomplete, inaccurate or
          not current, please use the Contact Us option on our Site, or call or
          write to us at the telephone numbers or appropriate address for
          changes listed on your account statements, records, online or other
          account materials. You can also speak to a customer representative
          from the Company.
        </>
      ),
    },
    {
      label: "Use of forums or chat rooms",
      markup: (
        <>
          The Site may from time to time include chat rooms, forums, message
          boards, and/ or news groups on which you can post information. Any
          information that you post in these areas becomes public information
          and you should always be careful when deciding to disclose your
          personal details as part of that information.
        </>
      ),
    },
    {
      label: "Security",
      markup: (
        <>
          To protect Personal Information from unauthorized access and use, we
          use security measures that comply with applicable federal laws. These
          measures may include device safeguards and secured files and buildings
          as well as oversight of our third-party service providers to ensure
          information remains confidential and secure.
          <br />
          <br />
          We have implemented technology and policies to safeguard your privacy
          from unauthorised access and improper use. We use secure server
          software (SSL) to encrypt any financial information you need to input
          before it is sent to us.
        </>
      ),
    },
    {
      label: "Linking to third-party websites and other aggregation website",
      markup: (
        <>
          <b>Linking to other sites:</b> We may provide links to third party
          sites, such as service providers or merchants. If you follow links to
          sites not affiliated or controlled by Brass, you should review their
          privacy and security policies and other terms and conditions, as they
          may be different from those of our Sites. Brass does not guarantee and
          is not responsible for the privacy or security of these sites,
          including the accuracy, completeness, or reliability of their
          information.
          <br />
          <br />
          We cannot be responsible for the privacy policies and practices of
          other sites even if you access them using links from our Site and
          recommend that you check the policy of each site you visit and contact
          its owner or operator if you have any concerns or questions.
          <br />
          <br />
          <b>Using Other Aggregation Websites:</b> Other companies offer
          aggregation websites and services that allow you to consolidate your
          financial account information from different sources (such as your
          accounts with us or with other financial institutions) so that you can
          view all your account information at one online location. To do this,
          an aggregation provider may request access to personal information,
          such as financial information, usernames and passwords. You should use
          caution and ensure that the aggregator company has appropriate
          policies and practices to protect the privacy and security of any
          information you provide or to which they are gaining access. We are
          not responsible for the use or disclosure of any personal information
          accessed by any company or person to whom you provide your Site
          username and password.
          <br />
          <br />
          If you provide your Site username, password or other information about
          your accounts with us to an aggregation website, we will consider that
          you have authorized all transactions or actions initiated by an
          aggregation website using access information you provide, whether or
          not you were aware of a specific transaction or action. If you decide
          to revoke the authority you have given to an aggregation website, we
          strongly recommend that you change your password for the Site to
          ensure that the aggregation website cannot continue to access your
          account.
        </>
      ),
    },
    {
      label: "Social media sites",
      markup: (
        <>
          Brass provides experiences on social media platforms including, but
          not limited to, Facebook®, Twitter®, and LinkedIn® that enable online
          sharing and collaboration among users who have registered to use them.
          Any content you post on official Brass managed social media pages,
          such as pictures, information, opinions, or any personal information
          that you make available to other participants on these social
          platforms, is subject to the Terms of Use and Privacy Policies of
          those respective platforms. Please refer to them to better understand
          your rights and obligations with regard to such content. In addition,
          please note that when visiting any official Brass social media page,
          you are also subject to Brass Privacy Policy.
        </>
      ),
    },
    {
      label: "Updates to this privacy policy",
      markup: (
        <>
          This privacy policy is subject to change. Please review it
          periodically. If we make changes to the Policy, we will revise the
          “Last Updated” date at the top of this Policy. Any changes to this
          Policy will become effective when we post the revised Policy on the
          Site. Your continued use of the Site following these changes means
          that you accept the revised Policy.
        </>
      ),
    },
    {
      label: "Contact us",
      markup: (
        <>
          If at any time you would like to contact us with your views about our
          privacy practices, or with any enquiry relating to your personal
          information, you can do so by sending an email to us at{" "}
          <a href="mailto:help@trybrass.com">help@trybrass.com</a> or writing to
          us at Gate 3, House A9, Alhaji Yekini Bakare Street, Lekki Peninsula
          II, Lagos.
        </>
      ),
    },
  ],
};
